<template>
  <div class="c-cover-name">
    <img class="c-cover-name__cover" src="@/assets/explore/playlists_img.jpg" />
    <p class="c-cover-name__name">
      Bags of Fun
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CoverName',
});
</script>
<style lang="scss" scoped>
.c-cover-name {
  @include flex();

  &__cover {
    width: 30px;
    height: 30px;
  }

  &__name {
    margin-left: 16px;
    @include font-style($c-black, 16, bold, 0.8px, 26px);
  }
}
</style>
