<template>
  <div class="c-note" @click="handleExpand('note')">
    <p class="count">10</p>
    <div class="expand">
      <img class="expand__img" :class="{ 'expand__img--active': expandStatus }" src="@/assets/icon/icon_expand.svg" />
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, computed } from 'vue';

export default defineComponent({
  name: 'NoteCount',

  setup() {
    const expandStatus = computed(() => inject('expandStatus')().note);
    const handleExpand = inject('handleExpand');

    return {
      expandStatus,
      handleExpand,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-note {
  @include flex();
  cursor: pointer;
}

.count {
  @include font-style($c-black, 14, normal, 0, 26px);
}

.expand {
  width: 10px;
  margin-left: 20px;

  &__img {
    transition: 0.3s;
    transform: rotate(0deg);

    &--active {
      transform: rotate(-180deg);
    }
  }
}
</style>
