import { h } from 'vue';
import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';
import CoverName from '@/components/Global/MusicPlayer/Fragment/CoverName.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import PlaylistsNameExpandFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameExpandFunction.vue';
import TrackDivideFunction from '@/components/Global/MusicPlayer/Fragment/TrackDivideFunction.vue';
import VersionAndEditNameFunction from '@/components/Global/MusicPlayer/Fragment/VersionAndEditNameFunction.vue';
import NoteCount from '@/components/Global/MusicPlayer/Fragment/NoteCount.vue';
import CountLabel from '@/components/Global/MusicPlayer/Fragment/CountLabel.vue';
import CoverNameCode from '@/components/Global/MusicPlayer/Fragment/CoverNameCode.vue';
import MoreNote from '@/components/Global/MusicPlayer/Fragment/MoreNote.vue';
// import More from '@/components/Global/MusicPlayer/Fragment/More.vue';

export const functionConfig = {
  search: {
    searchType: 'my-note', // 搜尋類別
    placeholder: '搜尋筆記',
  },
  info: {
    list: [],
  },
  listFilter: {
    checkboxs: [],
  },
  functionIcons: {
    list: [],
  },
  addPlayListBtn: {
    visible: false,
  },
};

export const mobileListConfig = {
  columns: [
    {
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      key: 'playlistsName',
      render: CoverNameCode,
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '60px',
      key: 'noteCount',
      render: h(CountLabel, {
        label: 'Notes',
      }),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '24px',
      key: 'more',
      render: h(MoreNote),
    },
  ],
};

export const pcListConfig = {
  columns: [
    {
      type: 'label',
      label: '曲目名稱',
      width: '300px',
      align: 'left',
      key: 'coverName',
      render: CoverName,
    },
    {
      type: 'label',
      label: '',
      // width: '300px',
      align: 'left',
      key: 'owner',
      render: MusicFunction,
    },

    {
      type: 'label',
      label: '筆記數量',
      width: '100px',
      align: 'left',
      key: 'noteCount',
      render: NoteCount,
    },

    {
      type: 'label',
      label: '長度',
      width: '100px',
      align: 'left',
      key: 'length',
    },
    {
      type: 'label',
      label: 'ID',
      width: '100px',
      align: 'left',
      key: 'id',
    },

    {
      type: 'label',
      label: '更新日期',
      width: '100px',
      align: 'left',
      key: 'modifyDate',
      render: ModifyDate,
    },

    // {
    //   type: 'checkbox',
    //   label: 'C',
    //   width: '60px',
    //   align: 'center',
    //   key: 'checkbox',
    // },
    {
      type: 'action',
      label: '',
      width: '60px',
      align: 'left',
      key: 'action',
    },

  ],

};

export const contentData = [
  {
    coverName: 'Bags of Fun',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    noteCount: 3,
    id: 'MAT305-76',

  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    noteCount: 2,
    id: 'MAT305-76',
  },
];

export const listDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    align: 'left',
    key: 'playlistsName',
    render: PlaylistsNameExpandFunction,
  },
  {
    type: 'label',
    label: '版本',
    width: '100px',
    align: 'left',
    key: 'version',
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
    render: Remove,
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'action',
    label: '',
    width: '60px',
    align: 'left',
    key: 'action',
  },
];

export const listDetailContentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '12:32',
    version: 5,
    trackNo: 5,
    id: 'MAT305-5',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,

    id: 'MAT305-76',
  },
];
export const musicDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    align: 'left',
    key: 'playlistsName',
    render: VersionAndEditNameFunction,
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'action',
    label: '',
    width: '60px',
    align: 'left',
    key: 'action',
  },
];

export const musicDetailContentData = [
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

export const trackDivideColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    align: 'left',
    key: 'playlistsName',
    render: TrackDivideFunction,
  },
  {
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    type: 'label',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'empty',
  },
];

export const trackDivideData = [
  {
    name: 'Clarinet&Basso',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Low Pizzicato',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];
