<template>
  <div class="v-my-note">
    <base-layout>
      <my-music-function-bar :config="functionConfig"/>
      <my-music-list :config="listConfig" :contentData="contentData"/>
    </base-layout>
  </div>
</template>
<script>
import {
  defineComponent, provide, computed, ref,
} from 'vue';

import BaseLayout from '@/components/MyMusic/BaseLayout.vue';
import MyMusicFunctionBar from '@/components/MyMusic/MyMusicFunctionBar.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import {
  functionConfig, contentData, mobileListConfig,
  pcListConfig,
} from '@/config/myMusic/myNoteConfig';

import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MyMusicMyNote',
  components: {
    BaseLayout,
    MyMusicList,
    MyMusicFunctionBar,
  },
  setup() {
    const siteStore = useSiteStore();
    const listContentConfig = {
      notice: '',
      expandDetail: 'note-list', // list-detail, music-detail, '' , note-list ...
    };

    const musicDetail = ref({

      // mobile 點擊 row 展開的 key
      mobileExpandKey: 'note',
    });

    const listConfig = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return pcListConfig;
      }

      return mobileListConfig;
    });

    provide('listContentConfig', listContentConfig);
    provide('musicDetail', musicDetail);

    return {
      functionConfig, listConfig, contentData,
    };
  },
});
</script>
<style lang="scss" scoped></style>
